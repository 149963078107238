import { render, staticRenderFns } from "./chat-dialog-message.vue?vue&type=template&id=c48773ae&scoped=true"
import script from "./chat-dialog-message.vue?vue&type=script&lang=ts"
export * from "./chat-dialog-message.vue?vue&type=script&lang=ts"
import style0 from "./chat-dialog-message.vue?vue&type=style&index=0&id=c48773ae&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c48773ae",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VCard,VIcon})
