







































































































































































































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { isArray } from 'lodash';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import ConversationService from '@/services/crm/conversation.service';
import ConversationMessageModel from '@/models/crm/conversation-message.model';
import { ConversationMessageStatusEnum } from '@/enums/crm/conversation-message-status.enum';
import { ConversationMessageTypeEnum } from '@/enums/crm/conversation-message-type.enum';
import { ConversationMessageErrorTypeEnum } from '@/enums/crm/conversation-message-error-type.enum';
import ConversationTemplateModel from '@/models/crm/conversation-template.model';
import CommServiceTokenModel from '@/models/comm-service-token.model';
import { IKeyValue } from '@/interfaces/key-value.interface';

@Component({})
export default class CrmChatDialogMessageContent extends Vue {
  @inject(InjectionIdEnum.CrmConversationService)
  protected conversationService!: ConversationService;

  @Prop({ required: true })
  message!: ConversationMessageModel;

  @Prop()
  replyMode!: boolean;

  @Prop()
  enableActions!: boolean;

  @Prop({ default: 'chat-bubble' })
  classes!: string;

  @Prop()
  blocked!: boolean;

  @Prop()
  eagerLoadImage!: boolean;

  @Prop({ default: () => [] })
  templates!: ConversationTemplateModel[];

  @Emit('delete-message')
  onDeleteMessage(): ConversationMessageModel {
    return this.message;
  }

  messageTypes = ConversationMessageTypeEnum;

  downloadUrl: string | null = '';

  lazyLoadUrl: string | null = '';

  private commServiceCredentials: CommServiceTokenModel | null = null;

  async mounted(): Promise<void> {
    this.commServiceCredentials = await this.conversationService.getCachedCommServiceToken();
    if (this.commServiceCredentials) {
      this.getDownloadUrl(this.commServiceCredentials);
      this.getLazyLoadUrl(this.commServiceCredentials);
    }
  }

  @Emit('start-load-image')
  onLoadingImage(): string {
    return this.message.id.toString();
  }

  @Emit('loaded-image')
  onLoadedImage(): string {
    return this.message.id.toString();
  }

  get templateText(): string {
    if (!this.message.content) {
      return '';
    }

    const messageTemplate = this.message.content.data.template;
    const templateName = messageTemplate.Name as string;

    if (this.templates.length) {
      const template = this.templates.find((x) => x.waTemplateName === templateName);

      if (template) {
        let parsedMessage = template.textoModeloMensagem;

        if (messageTemplate.Components && isArray(messageTemplate.Components)) {
          const bodyComponents = (messageTemplate.Components as IKeyValue<string | IKeyValue[]>[]).find(
            (x) => x.Type === 'body',
          );

          if (bodyComponents) {
            (bodyComponents.Parameters as IKeyValue<string>[]).forEach((param: IKeyValue, index: number) => {
              parsedMessage = parsedMessage.replace(`{{${index + 1}}}`, param.Text);
            });
          }
        }

        return parsedMessage;
      }
    }

    return templateName;
  }

  get templateImage(): string {
    if (!this.message.content) {
      return '';
    }

    const messageTemplate = this.message.content.data.template;
    const templateName = messageTemplate.Name as string;

    if (this.templates.length) {
      const template = this.templates.find((x) => x.waTemplateName === templateName);

      if (template) {
        return template.urlImagem;
      }
    }

    return templateName;
  }

  get documentFilename(): string {
    if (this.message.content?.data) {
      const { data } = this.message.content;
      return (data?.document?.Filename || data.temporary?.filename || '').toString();
    }

    return '';
  }

  get documentCaption(): string {
    const caption = (this.message.message || this.message.content?.data?.temporary?.caption || '').toString();

    if (caption !== this.documentFilename) {
      return caption;
    }

    return '';
  }

  get mediaUrl(): string {
    return this.downloadUrl || '';
  }

  get mediaLazyUrl(): string {
    return this.lazyLoadUrl || '';
  }

  get isTemporary(): boolean {
    const temporary = this.message.status.code === ConversationMessageStatusEnum.Temporary;
    return temporary;
  }

  get isTemporaryInError(): boolean {
    const temporaryInError = this.message.status.code === ConversationMessageStatusEnum.TemporaryInError;
    return temporaryInError;
  }

  get isInactive(): boolean {
    const deleted = this.message.status.code === ConversationMessageStatusEnum.Deleted;
    const error = this.message.status.code === ConversationMessageStatusEnum.Error;
    const failed = this.message.status.code === ConversationMessageStatusEnum.Failed;
    const temporaryInError = this.message.status.code === ConversationMessageStatusEnum.TemporaryInError;
    return deleted || error || failed || temporaryInError;
  }

  get showActionsMenu(): boolean {
    return this.enableActions && (this.showDeleteAction || this.showDownloadAction);
  }

  get showDeleteAction(): boolean {
    return !this.replyMode && this.isTemporaryInError;
  }

  get showDownloadAction(): boolean {
    return !this.replyMode && !this.isTemporary && !this.isInactive && this.isMediaMessage;
  }

  get isMediaMessage(): boolean {
    const isAudio = this.message.type.code === ConversationMessageTypeEnum.Audio;
    const isImage = this.message.type.code === ConversationMessageTypeEnum.Image;
    const isVideo = this.message.type.code === ConversationMessageTypeEnum.Video;
    const isDocument = this.message.type.code === ConversationMessageTypeEnum.Document;
    const isVoice = this.message.type.code === ConversationMessageTypeEnum.Voice;

    return isAudio || isImage || isVideo || isDocument || isVoice;
  }

  get errorMessageType(): string {
    return this.message.type.code === ConversationMessageTypeEnum.Text
      ? ConversationMessageErrorTypeEnum.Reaction
      : ConversationMessageErrorTypeEnum.Ephemeral;
  }

  get mapsUrl(): string {
    const { location } = this.message.content.data;
    return `https://www.google.com/maps/place/${location.Latitude},${location.Longitude}`;
  }

  openMedia(url: string): void {
    if (this.isTemporary || this.isInactive) {
      return;
    }

    window.open(url, '_blank');

    this.$emit('open-file', url);
  }

  onDownloadMedia(): void {
    window.open(this.mediaUrl, '_blank');
  }

  private async getDownloadUrl(credentials: CommServiceTokenModel): Promise<void> {
    this.downloadUrl = await this.conversationService.getMediaUrl(credentials, this.message);
  }

  private async getLazyLoadUrl(credentials: CommServiceTokenModel): Promise<void> {
    this.lazyLoadUrl = await this.conversationService.getMediaUrl(credentials, this.message, true);
  }
}
